import * as React from "react";
import { PaletteMode, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppAppBar from "./components/AppAppBar";
import Footer from "./components/Footer";
// import getMPTheme from './theme/getMPTheme';
// import PlayContainer from "./components/play/PlayContainer";
import { useDispatch } from "react-redux";
import { loadQuizStateFromStorage } from "../redux/dailyTriviaSlice";
import EndpointsAccordion from "./components/api/EndpointsAccordion";
import {
  Box,
  Container,
  Link,
  List,
  ListItem,
  // ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
// import CircleIcon from "@mui/icons-material/Circle"; // Import the circle icon
import CodeWrapper from "./components/api/CodeWrapper";

export default function APIPage() {
  React.useEffect(() => {
    const savedMode = localStorage.getItem("themeMode") as PaletteMode | null;
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadQuizStateFromStorage());
  }, [dispatch]);

  const [mode, setMode] = React.useState<PaletteMode>("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  // const theme = createTheme({
  //   palette: {
  //     mode: mode === "dark" ? "dark" : "light", // Toggle between light and dark modes
  //     ...(mode
  //       ? {
  //           // Dark mode specific palette
  //           text: {
  //             primary: "#ffffff", // Default white text for dark mode
  //             secondary: "#bbbbbb",
  //           },
  //         }
  //       : {
  //           // Light mode specific palette
  //           text: {
  //             primary: "#000000", // Default black text for light mode
  //             secondary: "#333333",
  //           },
  //         }),
  //   },
  //   typography: {
  //     // Define the font styles here if you need to
  //     fontFamily: "Roboto, Arial, sans-serif", // Example font
  //   },
  // });

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* // <ThemeProvider theme={theme}> */}
      <CssBaseline enableColorScheme />

      <AppAppBar
        toggleCustomTheme={toggleCustomTheme}
        showCustomTheme={showCustomTheme}
        mode={mode}
        toggleColorMode={toggleColorMode}
      />
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        {" "}
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Typography variant="h3" color="textPrimary">
          API Docs
        </Typography>
        <Box
          sx={{
            textAlign: "left",
            width: "80%",
          }}
        >
          <Typography variant="h4" color="textPrimary">
            Introduction
          </Typography>
          <Divider />
          <br />
          <Typography variant="body1" color="textPrimary">
            Trivia owl is a REST API built in Go with performance in mind.
            Anyone can use Trivia Owl with a free API key provisioned through
            RapidAPI, but certain features and endpoints are restricted to paid
            keys.
            <br />
            <br />
            Authorization can be obtained via RapidAPI and is required for all
            requests.
            <br />
            <br />
            Give it a try in your terminal now:
            <CodeWrapper
              codeString={"curl -X GET https://api.triviaowls.xyz/v1/ping"}
              language="bash"
              showNum={false}
              showClipboard={true}
            />
            <br />
            <br />
            <strong>
              More information available, tests, and API playground available on{" "}
              <Link
                href="https://rapidapi.com/ayeagle/api/trivia-owls/"
                target="_blank"
                underline="hover"
              >
                RapidAPI
              </Link>
              .
            </strong>
          </Typography>
          <br />
          <br />
          <Typography variant="h4" color="textPrimary">
            API Overview
          </Typography>
          <Divider />
          <br />
          <Typography variant="body1" color="textPrimary">
            Trivia owl offers several different endpoints for different
            purposes, but in most cases users our users focus on two in
            particular:
            <List>
              <ListItem>
                <ListItemText
                  primary="/random"
                  secondary="Generate random questions based on selected search criteria."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="/checkAnswer"
                  secondary="Check free form answer correctness with proprietary text matching algorithm."
                />
              </ListItem>
            </List>
          </Typography>
          <Typography variant="body1" color="textPrimary">
            Other helpful endpoints include:
            <List>
              <ListItem>
                <ListItemText
                  primary="/rate"
                  secondary="Rate question quality and difficulty to improve inventory. Usage earns api credits."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="/dailyTrivia"
                  secondary="Endpoint for generating curated daily quiz of 5 questions."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="/categories"
                  secondary="Fetch all viable question categories available."
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary="/ping"
                  secondary="Check uptime and server performance."
                />
              </ListItem>
            </List>
          </Typography>
        </Box>
        <EndpointsAccordion />
        {/* <LogoCollection /> */}
        {/* <Features /> */}
        {/* <Divider />
          <Testimonials /> */}
        <Divider />
        {/* <Highlights />
          <Divider />
          <Pricing />
          <Divider /> */}
        {/* <FAQ />
          <Divider /> */}
        <Footer />
      </Container>
    </ThemeProvider>
  );
}
