import { createSlice } from "@reduxjs/toolkit";

export interface GlobalState {
  newsletterSignupCompleted: boolean;
  hasSeenTutorial: boolean;
}

export const initialState: GlobalState = {
  newsletterSignupCompleted: false,
  hasSeenTutorial: false,
};

const globalStateSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {
    setNewsletterSignupCompleted(state) {
      state.newsletterSignupCompleted = true;
    },
    setHasSeenTutorialCompleted(state) {
      state.hasSeenTutorial = true;
    },
  },
});

export const { setNewsletterSignupCompleted, setHasSeenTutorialCompleted } =
  globalStateSlice.actions;
export default globalStateSlice.reducer;
