import { ReactNode } from "react";
import styles from "./StandardButton.module.css";
import { Button, ButtonClasses } from "@mui/material";
import { ButtonProps } from "@mui/material"; // Import the MUI ButtonProps to use their color type

export default function StandardButton({
  fx,
  children,
  override_styles,
  color,
  disabled,
  classes,
}: {
  fx: () => void;
  children: ReactNode;
  override_styles?: string;
  color?: ButtonProps["color"];
  disabled?: boolean;
  classes?: Partial<ButtonClasses>;
}) {
  const styles_to_use = override_styles
    ? override_styles
    : styles.button_container;

  return (
    // <h3 className={styles.button_inner_text}>
    <Button
      color={color ?? "primary"}
      className={styles_to_use}
      onClick={fx}
      disabled={disabled ?? false}
      classes={classes ?? ""}
    >
      <p className={styles.button_inner_text}>{children}</p>
    </Button>
    // </h3>
  );
}
