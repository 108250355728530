import * as React from "react";
import Accordion from "@mui/material/Accordion";
// import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Button from "@mui/material/Button";
import { Box, Divider, Typography } from "@mui/material";
// import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
// import * as things from "react-syntax-highlighter/dist/esm/styles/prism";

// import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
// import { useTheme } from "@mui/material/styles";

// import prettier from "prettier/standalone";
// import parserBabel from "prettier/parser-babel";
import CodeWrapper from "./CodeWrapper";

export default function DataModelOverview() {
  const difficulty_code = `export enum DifficultyEnum {
      EASY = 'easy',
      MEDIUM = 'medium',
      HARD = 'hard'
};`;

  const type_code = `export enum QuestionTypeEnum {
    MULTIPLE_CHOICE = 'multiple_choice'
    SHORT_ANSWER = 'short_answer'
    TRUE_FALSE = 'true_false'
};`;

  const question_code = `export type TriviaOwlQuestion = {
    question_id: string;
    question: string;
    question_type: QuestionTypeEnum; // string
    question_tags: TriviaCategory[]; // string[]
    difficulty: DifficultyEnum; // string
    accepted_correct_answers: string[];
    incorrect_multiple_choices?: string[];
    hint: string;
    is_niche: boolean;
    is_nsfw: boolean;
};`;

  return (
    // <div style={{ paddingTop: "10px" }}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Data Model Overview
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        Below is the data model for request and responses.
      </AccordionDetails>
      <Box
        sx={{
          textAlign: "left",
          paddingLeft: "15px",
        }}
      >
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ padding: "10px" }}
        >
          Quesiton Data Model
        </Typography>
        <CodeWrapper codeString={question_code} />
        <br />
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ padding: "10px" }}
        >
          Difficulty values
        </Typography>
        <CodeWrapper codeString={difficulty_code} />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ padding: "10px" }}
        >
          Medium is the most common difficulty available, with proportially
          fewer easy and hard questions.
        </Typography>
        <br />
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ padding: "10px" }}
        >
          Question type values
        </Typography>
        <CodeWrapper codeString={type_code} />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ padding: "10px" }}
        >
          The most common question type is short answer, with the second most
          multiple choice. Because of the easy nature of true/false questions,
          they represent a small portion of the question inventory.
        </Typography>
        <br />
      </Box>
    </Accordion>
    // </div>
  );
}
