import * as React from "react";
// import Accordion from "@mui/material/Accordion";
// import AccordionActions from "@mui/material/AccordionActions";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box, Typography } from "@mui/material";
import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
import * as things from "react-syntax-highlighter/dist/esm/styles/prism";

// import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useTheme } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// import prettier from "prettier/standalone";
// import parserBabel from "prettier/parser-babel";
// import styles from "./CodeWrapper.module.css";

interface CodeWrapperProps {
  codeString: string;
  language?: string;
  showNum?: boolean;
  showClipboard?: boolean;
}

export default function CodeWrapper(props: CodeWrapperProps) {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const onCopyCodeSnippet = () => {
    navigator.clipboard.writeText(props.codeString).then(() => {});
  };

  return (
    <Typography
      component="pre"
      variant="body2"
      sx={{
        // backgroundColor: "#f5f5f5",
        // color: "inherit"
        // padding: "16px",
        borderRadius: "20px",
        // overflowX: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SyntaxHighlighter
          language={props.language ?? "typescript"}
          showLineNumbers={props.showNum ?? true}
          wrapLongLines={false}
          // style={isDarkMode ? dark : undefined}
          style={isDarkMode ? things.vscDarkPlus : things.oneLight}
          override_style={{
            color: "inherit",
            width: "110vw",
            marginRight: "20px",
          }}
          // useInlineStyles={true}
        >
          {props.codeString}
        </SyntaxHighlighter>
        {props.showClipboard && (
          <Button
            variant="text"
            size="small"
            onClick={onCopyCodeSnippet}
            style={{ maxHeight: "45px", top: "7px" }}
          >
            <ContentCopyIcon
              fontSize="medium"
              // className={styles.copy_icon}
              // style={{ position: "relative", top: "5px" }}
            />
          </Button>
        )}
      </Box>
    </Typography>
  );
}
