import { useEffect, useState } from "react";
import styles from "./PlayCard.module.css";
// import axios from "axios";

import { PlaySource, SegmentOptions } from "../../../data/Enums";
import FlexBox from "../../../legacy_components/utils/FlexBox";
import {
  Box,
  // CircularProgress,
  Container,
  LinearProgress,
  Pagination,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PlayCard from "./PlayCard";
import { AppDispatch, RootState } from "../../../redux/store";
// import { Button } from "@mui/material";

import {
  addTriviaQuestions,
  fetchRandomTriviaData,
  // incrementRandomQuestionsVisible,
  // incrementRandomQuestionIdx,
  setRandomQuestionIdx,
  setTriviaData,
} from "../../../redux/randomTriviaSlice";

// PAGINATE

const currentDate = new Intl.DateTimeFormat("en-US", {
  timeZone: "America/Los_Angeles",
  year: "2-digit",
  month: "numeric",
  day: "numeric",
}).format(new Date());
console.log("this is going");

export default function PlayCardRandom({
  votedQs,
  setVotedQs,
}: {
  votedQs: Set<string>;
  setVotedQs: React.Dispatch<React.SetStateAction<Set<string>>>;
}) {
  // const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  // const [diffFeedbackSubmitted, setDiffFeedbackSubmitted] =
  //   useState<boolean>(false);
  // const [showBadQuestionReasons, setShowBadQuestionReasons] =
  //   useState<boolean>(false);
  // const [questionRatedBad, setQuestionRatedBad] = useState<boolean>(false);
  const [currSegment, setCurrSegment] = useState<string>(SegmentOptions.REVIEW);

  const dispatch = useDispatch<AppDispatch>();

  const randomTriviaQuestions = useSelector(
    (state: RootState) => state.randomTrivia.randomTriviaQuestions
  );
  const queryParams = useSelector(
    (state: RootState) => state.randomTrivia.queryParams
  );
  const loading = useSelector((state: RootState) => state.randomTrivia.loading);
  const error = useSelector((state: RootState) => state.randomTrivia.error);

  const randomTriviaIdx = useSelector(
    (state: RootState) => state.randomTrivia.randomTriviaIdx
  );
  const correctCount = useSelector(
    (state: RootState) => state.randomTrivia.correctCount
  );

  const questionsVisible = useSelector(
    (state: RootState) => state.randomTrivia.questionsVisible
  );

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    console.log("Page value -1");
    console.log(value - 1);
    console.log("Page questionsVisible");
    console.log(questionsVisible);
    console.log("Page randomTriviaIdx + 1");
    console.log(randomTriviaIdx + 1);

    dispatch(setRandomQuestionIdx(value - 1));
  };

  // const postQuestionRating = (
  //   quality_rating: number,
  //   difficulty_rating: number,
  //   bad_question_reason: string
  // ) => {
  //   axios.post("https://api.triviaowls.xyz/v1/rate", {
  //     question_id: randomTriviaQuestions[randomTriviaIdx].question_id,
  //     quality_rating,
  //     difficulty_rating,
  //     bad_question_reason,
  //   });

  //   const qs_copy = votedQs.add(
  //     randomTriviaQuestions[randomTriviaIdx].question_id
  //   );

  //   setVotedQs(qs_copy);
  //   if (quality_rating !== 0) {
  //     setFeedbackSubmitted(true);
  //   }
  //   if (quality_rating === -1) {
  //     setQuestionRatedBad(true);
  //   }
  //   if (difficulty_rating !== 0) {
  //     setDiffFeedbackSubmitted(true);
  //   }
  //   if (bad_question_reason !== "") {
  //     setShowBadQuestionReasons(false);
  //   }
  // };

  // useEffect(() => {
  //   if (diffFeedbackSubmitted && feedbackSubmitted) {
  //     setTimeout(() => {
  //       dispatch(incrementRandomQuestionIdx());
  //     }, 2000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [diffFeedbackSubmitted, feedbackSubmitted]);

  const fetchAndHandleTrivia = async ({
    fetch_cnt,
    override_questions,
  }: {
    fetch_cnt: number;
    override_questions?: boolean;
  }) => {
    const resultAction = await dispatch(
      fetchRandomTriviaData({ fetch_cnt: fetch_cnt ?? 5, ...queryParams })
    );
    if (fetchRandomTriviaData.fulfilled.match(resultAction)) {
      const newQuestions = resultAction.payload;
      console.log("newQuestions");
      console.log(newQuestions);

      if (override_questions) {
        console.log("result is override");

        dispatch(
          setTriviaData([
            ...randomTriviaQuestions.slice(0, randomTriviaIdx + 1),
            ...newQuestions,
          ])
        );
      } else {
        console.log("result is NOT override");

        dispatch(addTriviaQuestions(newQuestions));
      }
    }
    console.log("NOT FULFILLED");
  };

  useEffect(() => {
    console.log("Base fetch");
    fetchAndHandleTrivia({ fetch_cnt: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   console.log("ATTEMPTING TO FETCH MORE RANDOM DATA");
  //   if (
  //     randomTriviaIdx === randomTriviaQuestions.length - 1 &&
  //     // diffFeedbackSubmitted &&
  //     // feedbackSubmitted
  //   ) {
  //     console.log("finna do it");

  //     fetchAndHandleTrivia({ fetch_cnt: 1 });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [randomTriviaIdx, diffFeedbackSubmitted, feedbackSubmitted]);

  console.log("******** randomTriviaQuestions");
  console.log(randomTriviaQuestions);

  useEffect(() => {
    if (
      currSegment === SegmentOptions.HINT ||
      currSegment === SegmentOptions.ANSWER
    ) {
      setCurrSegment("");
    }

    // setFeedbackSubmitted(false);
    // setDiffFeedbackSubmitted(false);
    // setShowBadQuestionReasons(false);
    // setQuestionRatedBad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [randomTriviaIdx]);

  if (
    !randomTriviaQuestions ||
    randomTriviaQuestions.length === 0 ||
    loading ||
    error
  ) {
    if (loading && randomTriviaQuestions.length > 0 && !error) {
      // do nothing lmao
    } else {
      return (
        <Box
          sx={{
            position: "relative",
            left: "15%",
            width: "70%",
            paddingTop: "15%",
          }}
        >
          <LinearProgress />
        </Box>
      );
      // return <BarLoader color="#1871c1" height="10" width="400" />;
    }
  }

  if (!randomTriviaQuestions) {
    return <></>;
  }

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 2 },
      }}
    >
      <Container
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: { xs: 3, sm: 2 },
          width: "100%",
        }}
      >
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            textAlign: "left",
          }}
        >
          {currentDate}
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
          style={{ textAlign: "center" }}
        >
          Free Play
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ textAlign: "right" }}
        >
          Score: {correctCount}
        </Typography>
      </Container>
      <FlexBox dir="row">
        <Pagination
          count={questionsVisible}
          page={randomTriviaIdx + 1}
          onChange={handlePageChange}
          color="primary"
        />
      </FlexBox>
      <div className={styles.question_container}>
        <Box
          sx={{
            width: "100%",
            padding: "auto",
            typography: "body1",
            justifyContent: "center",
            maxWidth: "100%",
          }}
        >
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ position: "relative", left: "10%", width: "80%" }}
          >
            Rate questions to keep playing and help improve our trivia
            experience.
          </Typography>
          <PlayCard
            source={PlaySource.RANDOM}
            question={randomTriviaQuestions[randomTriviaIdx]}
            votedQs={votedQs}
            setVotedQs={setVotedQs}
          />
          {/* <hr style={{ width: "100%" }}></hr> */}
        </Box>
      </div>
    </Container>
  );
}
