import * as React from "react";
import { PaletteMode, ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppAppBar from "./components/AppAppBar";
import Footer from "./components/Footer";
// import getMPTheme from './theme/getMPTheme';
import PlayContainer from "./components/play/PlayContainer";
import { useDispatch } from "react-redux";
import { loadQuizStateFromStorage } from "../redux/dailyTriviaSlice";

export default function PlayPage() {
  React.useEffect(() => {
    const savedMode = localStorage.getItem("themeMode") as PaletteMode | null;
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(loadQuizStateFromStorage());
  }, [dispatch]);

  
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  // const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); 
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  // const theme = createTheme({
  //   palette: {
  //     mode: mode === "dark" ? "dark" : "light", // Toggle between light and dark modes
  //     ...(mode
  //       ? {
  //           // Dark mode specific palette
  //           text: {
  //             primary: "#ffffff", // Default white text for dark mode
  //             secondary: "#bbbbbb",
  //           },
  //         }
  //       : {
  //           // Light mode specific palette
  //           text: {
  //             primary: "#000000", // Default black text for light mode
  //             secondary: "#333333",
  //           },
  //         }),
  //   },
  //   typography: {
  //     // Define the font styles here if you need to
  //     fontFamily: "Roboto, Arial, sans-serif", // Example font
  //   },
  // });

  return (
    <ThemeProvider theme={defaultTheme}>
    {/* // <ThemeProvider theme={theme}> */}
    <CssBaseline enableColorScheme />

      <AppAppBar
        toggleCustomTheme={toggleCustomTheme}
        showCustomTheme={showCustomTheme}
        mode={mode}
        toggleColorMode={toggleColorMode}
      />
      <PlayContainer />
      <div>
        {/* <LogoCollection /> */}
        {/* <Features /> */}
        {/* <Divider />
          <Testimonials /> */}
        <Divider />
        {/* <Highlights />
          <Divider />
          <Pricing />
          <Divider /> */}
        {/* <FAQ />
          <Divider /> */}
        <Footer />
      </div>
    </ThemeProvider>
  );
}
