import * as React from "react";
import Accordion from "@mui/material/Accordion";
// import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Button from "@mui/material/Button";
import { Box, Divider, Typography } from "@mui/material";
// import { PrismAsyncLight as SyntaxHighlighter } from "react-syntax-highlighter";
// import * as things from "react-syntax-highlighter/dist/esm/styles/prism";

// import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
// import { useTheme } from "@mui/material/styles";

// import prettier from "prettier/standalone";
// import parserBabel from "prettier/parser-babel";
import CodeWrapper from "./CodeWrapper";

interface EndpointsListingProps {
  display_title: string;
  endpoint_name?: string;
  endpoint_details: string;
  request_code_block: string;
  request_code_notes: string;
  response_code_block: string;
  response_code_notes: string;
}

export default function EndpointsListing(props: EndpointsListingProps) {
  // const theme = useTheme();
  // const isDarkMode = theme.palette.mode === "dark";

  return (
    // <div>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        {props.display_title}
      </AccordionSummary>
      <Divider />
      <AccordionDetails>
        {props.endpoint_name && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "left",
              paddingLeft: "10px",
            }}
          >
            <Typography variant="body2" color="textPrimary">
              Path:&nbsp;&nbsp;
            </Typography>
            <Typography
              variant="body2"
              color="textPrimary"
              style={{ fontFamily: "monospace" }}
            >
              {props.endpoint_name}
            </Typography>
          </Box>
        )}
      </AccordionDetails>
      <Box
        sx={{
          textAlign: "left",
          paddingLeft: "15px",
        }}
      >
        <AccordionDetails sx={{ padding: "10px" }}>
          {props.endpoint_details}
        </AccordionDetails>
        {/* <div
          style={{
            // width: "300px",
            margin: "0 auto",
            padding: "5px",
            // borderRadius: "20px",
            textAlign: "left",
          }}
        > */}
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ padding: "10px" }}
        >
          Request Parameters:
        </Typography>
        <CodeWrapper codeString={props.request_code_block} />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ padding: "10px" }}
        >
          {props.request_code_notes}
        </Typography>
        <Typography
          variant="body2"
          color="textPrimary"
          sx={{ padding: "10px" }}
        >
          Response Body:
        </Typography>
        <CodeWrapper codeString={props.response_code_block} />
        <Typography
          variant="body2"
          color="textSecondary"
          sx={{ padding: "10px" }}
        >
          {props.response_code_notes}
        </Typography>
      </Box>
    </Accordion>
    // </div>
  );
}
