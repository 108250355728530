import * as React from "react";
// import SvgIcon from "@mui/material/SvgIcon";
import FlexBox from "../../legacy_components/utils/FlexBox";
// import styles from "./SiteMarkIcon.module.css";
import { useNavigate } from "react-router-dom";

export default function SitemarkIcon() {
  const navigate = useNavigate();

  const handleNavigate = (loc: string) => {
    navigate(loc);
  };

  return (
    <div onClick={() => handleNavigate("/")}>
      <FlexBox dir="row">
        <FlexBox dir="col">
          <img
            style={{ maxWidth: "50px" }}
            src="/media/trivial_owl_blue.png"
            alt="logo"
            // className={styles.original}
          />
          {/* <img
            style={{ maxWidth: "50px" }}
            src="/media/trivial_owl_blue.png"
            alt="logo"
            className={styles.backlight}
          /> */}
          {/* Trivia Owl API */}
        </FlexBox>
      </FlexBox>
    </div>
  );
}
