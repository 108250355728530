import { ReactNode } from "react";

export default function FlexBox({
  dir,
  children,
}: {
  dir: string;
  children: ReactNode;
}) {
  const direction = dir === "row" ? "row" : "column";
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: direction,
      }}
    >{children}</div>
  );
}
