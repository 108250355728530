import * as React from "react";
// import Accordion from "@mui/material/Accordion";
// import AccordionActions from "@mui/material/AccordionActions";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Button from "@mui/material/Button";
import EndpointsListing from "./EndpointListing";
import DataModelOverview from "./DataModelOverview";


export default function EndpointsAccordion() {
  const random_request = `export type TriviaOwlQuestionRequest = {
  difficulties?: string[];      // set difficulties allowed in response
  question_types?: string[];    // set types of questions allowed in response
  topics?: string[];            // set allowed question topics/categories
  exclude_niche?: boolean;      // exclude niche questions
  exclude_nsfw?: boolean;       // exclude nsfw questions
  max_question_chars?: number;  // exclude questions over a certain length
  vetted_only?: boolean;        // only use questions with positive feedback ratings
  limit?: number;               // number of questions to return (default max 10)
}`;

  const random_response = `body: TriviaOwlQuestionResponse[]

export type TriviaOwlQuestionResponse = {
    question_id: string;
    question: string;
    question_type: QuestionTypeEnum; // string
    question_tags: TriviaCategory[]; // string[]
    difficulty: DifficultyEnum; // string
    accepted_correct_answers: string[];
    incorrect_multiple_choices?: string[];
    hint: string;
    is_niche: boolean;
    is_nsfw: boolean;
};`;

  const check_ans_request = `export type CheckAnswerRequest = {
  question_id: string;
  user_answer: string;
  accepted_correct_answers: string[];
};`;

  const check_ans_response = `body: CheckAnswerResponse

export type CheckAnswerResponse = {
  question_id: string;
  user_answer: string;
  accepted_correct_answers: string[];
  user_answer_correct: number;
};`;

  const rate_request = `export type RateQuestionRequest = {
  question_id: string;
  difficulty_rating?: number;
  quality_rating?: number;
  bad_question_reason?: string;
};`;

  const daily_response = `body: TriviaOwlQuestionResponse[] // length of 5

export type TriviaOwlQuestionResponse = {
    question_id: string;
    question: string;
    question_type: QuestionTypeEnum; // string
    question_tags: TriviaCategory[]; // string[]
    difficulty: DifficultyEnum; // string
    accepted_correct_answers: string[];
    incorrect_multiple_choices?: string[];
    hint: string;
    is_niche: boolean;
    is_nsfw: boolean;
};`;

  const daily_request = `{}`;

  const rate_response = `{}`;

  return (
    <div style={{ maxWidth: "95%" }}>
      <DataModelOverview />
      <EndpointsListing
        display_title={"Fetch Questions"}
        endpoint_name={"/v1/random"}
        endpoint_details={
          "This endpoint can be used to fetch questions based on search criteria."
        }
        request_code_block={random_request}
        request_code_notes="Any combination of arguments is accepted per request, but if filtering on hyper-specific criteria there is a chance that an empty array is returned."
        response_code_block={random_response}
        response_code_notes=""
      />
      <EndpointsListing
        display_title={"Check Answers"}
        endpoint_name={"/v1/checkAnswer"}
        endpoint_details={
          "This endpoint can be used to check user inputs for short answers with our proprietary algorithm."
        }
        request_code_block={check_ans_request}
        request_code_notes=""
        response_code_block={check_ans_response}
        response_code_notes="A user_answer_correct value of -1 indicates an incorrect guess, 0 indicates a close guess either by being slightly too mispelled or containing key words, and 1 represents a very close fuzzy match on the correct answer."
      />
      <EndpointsListing
        display_title={"Rate Questions"}
        endpoint_name={"/v1/rate"}
        endpoint_details={
          "This endpoint allows users to rate the quality and difficulty of questions. Valid ratings requests attached a RapidAPI key will earn usage credits for the account, but spamming may result in suspension of blacklisting of the key and account."
        }
        request_code_block={rate_request}
        request_code_notes="Difficulty ratings are 1-5, 1 for easiet and 5 for hardest questions. Quality is -1 for a bad question, and 1 for a good question. 0 is not registered as a rating for either."
        response_code_block={rate_response}
        response_code_notes="No response body."
      />
      <EndpointsListing
        display_title={"Daily Trivia"}
        endpoint_name={"/v1/dailyTrivia"}
        endpoint_details={
          "This endpoint returns the five questions for daily trivia. No search arguments are accepted so that all users globally play the same questions."
        }
        request_code_block={daily_request}
        request_code_notes="No search/request parameters accepted for this endpoint"
        response_code_block={daily_response}
        response_code_notes=""
      />
      {/* <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          Accordion 2
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          Accordion Actions
        </AccordionSummary>
        <AccordionDetails>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          malesuada lacus ex, sit amet blandit leo lobortis eget.
        </AccordionDetails>
        <AccordionActions>
          <Button>Cancel</Button>
          <Button>Agree</Button>
        </AccordionActions>
      </Accordion> */}
    </div>
  );
}
