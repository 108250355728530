export enum SegmentOptions {
  // NONE = "NONE",
  HINT = "HINT",
  ANSWER = "ANSWER",
  FILTER = "FILTER",
  REVIEW = "REVIEW",
}

export enum Difficulty {
  VERY_EASY = "VERY_EASY",
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
  VERY_HARD = "VERY_HARD",
}

export enum PlaySource {
  DAILY = "DAILY",
  RANDOM = "RANDOM",
}
