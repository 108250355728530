import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CancelIcon from "@mui/icons-material/Cancel";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const tiers = [
  {
    title: "Hobbyist",
    price: "0",
    improved: [],
    included: [
      "1k calls/month",
      "¢.01 per additional call",
      "Max 1 question per call",
      "Max 1 call/IP/10 sec",
    ],
    new_items: [],
    excluded: [
      "Commercial use",
      "Filter on category, difficulty, niche, nsfw",
      "Multiple correct answer formats",
      "Access to /checkAnswer endpoint",
      "Javascript SDKs",
      "Email support",
      "Custom quiz creation",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
  {
    title: "Professional",
    subheader: "Best",
    price: "7.99",
    improved: [
      "10k calls/month",
      "¢.005 per additional call",
      "Max 10 questions per call",
      "Max 1 call/IP/5 sec",
    ],

    included: [],
    new_items: [
      "Commercial use",
      "Filter on category, difficulty, niche, nsfw",
      "Multiple correct answer formats",
      "Access to /checkAnswer endpoint",
    ],
    excluded: ["Javascript SDKs", "Email support", "Custom quiz creation"],
    buttonText: "Start now",
    buttonVariant: "contained",
    buttonColor: "secondary",
  },
  {
    title: "Expert",
    price: "44.99",
    improved: [
      "100k calls/month",
      "¢.001 per additional call",
      "Max 10 questions per call",
      "No rate limiting",
    ],

    included: [
      "Commercial use",
      "Filter on category, difficulty, niche, nsfw",
      "Multiple correct answer formats",
      "Access to /checkAnswer endpoint",
    ],
    new_items: ["Javascript SDKs", "Email support"],
    excluded: ["Custom quiz creation"],
    buttonText: "Start now",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
  {
    title: "Enterprise",
    price: "-1",
    improved: [
      "10m calls/month",
      "Flexible overage pricing",
      "Max 10 questions per call",
    ],
    included: [
      "No rate limiting",
      "Commercial use",
      "Filter on category, difficulty, niche, nsfw",
      "Multiple correct answer formats",
      "Access to /checkAnswer endpoint",
      "Javascript SDKs",
    ],
    new_items: ["Enterprise support", "Custom quiz creation"],
    excluded: [],
    buttonText: "Contact us",
    buttonVariant: "outlined",
    buttonColor: "primary",
  },
];

export default function Pricing() {
  return (
    <Container
      id="pricing"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: "100%", md: "60%" },
          textAlign: { sm: "left", md: "center" },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "text.primary" }}
        >
          Pricing
        </Typography>
        <Typography variant="body1" sx={{ color: "text.secondary" }}>
          Whether you need delivery for millions of concurrent users, help with
          a night of pub-trivia, or just building a personal project, we have
          options for everyone. <br />
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        sx={{ alignItems: "center", justifyContent: "center", width: "100%" }}
      >
        {tiers.map((tier) => (
          <Grid
            // size={{ xs: 12, sm: tier.title === "Enterprise" ? 12 : 6, md: 3 }}
            size={{ xs: 12, sm: 6, md: 3 }}
            key={tier.title}
          >
            <Card
              sx={[
                {
                  p: 2,
                  display: "flex",
                  flexDirection: "column",
                  gap: 4,
                },
                tier.title === "Professional" &&
                  ((theme) => ({
                    border: "none",
                    background:
                      "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                    boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                    ...theme.applyStyles("dark", {
                      background:
                        "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                      boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                    }),
                  })),
              ]}
            >
              <CardContent>
                <Box
                  sx={[
                    {
                      mb: 1,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2,
                    },
                    tier.title === "Professional"
                      ? { color: "grey.100" }
                      : { color: "" },
                  ]}
                >
                  <Typography component="h3" variant="h6">
                    {tier.title}
                  </Typography>
                  {tier.subheader && (
                    <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                  )}
                </Box>
                <Box
                  sx={[
                    {
                      display: "flex",
                      alignItems: "baseline",
                    },
                    tier.title === "Professional"
                      ? { color: "grey.50" }
                      : { color: null },
                  ]}
                >
                  {tier.price === "-1" ? (
                    <Typography component="h3" variant="h4">
                      Contact
                    </Typography>
                  ) : (
                    <>
                      <Typography component="h4" variant="h4">
                        ${tier.price}
                      </Typography>
                      <br />
                      <Typography component="h4" variant="h6">
                        &nbsp; /month
                      </Typography>
                    </>
                  )}
                </Box>
                <Divider sx={{ my: 2, opacity: 0.8, borderColor: "divider" }} />
                {tier.improved.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <AddTaskIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === "Professional"
                          ? { color: "primary.light" }
                          : { color: "primary.main" },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={[
                        tier.title === "Professional"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
                {tier.included.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === "Professional"
                          ? { color: "primary.light" }
                          : { color: "primary.main" },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={[
                        tier.title === "Professional"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
                {tier.new_items.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleOutlineIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === "Professional"
                          ? { color: "success.light" }
                          : { color: "success.main" },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={[
                        tier.title === "Professional"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
                {tier.excluded.map((line) => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: "flex",
                      gap: 1.5,
                      alignItems: "center",
                    }}
                  >
                    <CancelIcon
                      sx={[
                        {
                          width: 20,
                        },
                        tier.title === "Professional"
                          ? { color: "grey" }
                          : { color: "grey" },
                      ]}
                    />
                    <Typography
                      variant="subtitle2"
                      component={"span"}
                      sx={[
                        tier.title === "Professional"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant as "outlined" | "contained"}
                    color={tier.buttonColor as "primary" | "secondary"}
                    href="https://rapidapi.com/ayeagle/api/trivia-owls/pricing"
                    target="_blank"
                  >
                    {tier.buttonText}
                  </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
